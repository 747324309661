// import React, { useState, useEffect } from "react";
// import { NavLink, useLocation } from "react-router-dom";
// import { lendingDashboardSideMenu, dashboardSideMenu, subHeaderMenu } from "../../common/json/MenuContent";
// import { AiOutlineMenu } from "react-icons/ai";
// import isResourceAvailable from "utils/permit_util";

// const SidebarContent = () => {
//   const location = useLocation();
//   const [menuItems, setMenuItems] = useState();
//   const [activePath, setActivePath] = useState(location.pathname);
//   const [activeSublink, setActiveSublink] = useState("disabled");
//   const [menuClose, setMenuClose] = useState(false);

//   const rolePermission = isResourceAvailable("role");
//   useEffect(() => {
//     const handleMediaChange = (e) => {
//       setMenuClose(e.matches);
//     };
//     const mediaQuery = window.matchMedia("(max-width: 990px)");
//     mediaQuery.addEventListener("change", handleMediaChange);
//     setMenuClose(mediaQuery.matches);
//     return () => {
//       mediaQuery.removeEventListener("change", handleMediaChange);
//     };
//   }, []);

//   useEffect(() => {
//     setMenuItems(location.pathname === "/" ? lendingDashboardSideMenu : dashboardSideMenu);
//   }, [location]);

//   const handleMenuItemClick = (item, index) => {
//     setActivePath(item.path);
//   };

//   const handleSubItemClick = (subItem) => {
//     setActiveSublink(subItem.link);
//   };

//   return (
//     <>
//       <div className={`sideBar ${menuClose ? "sideBar-close" : ""}`} style={{ height: "750px" }}>
//         <hr
//           style={{
//             color: "#827B7B",
//             marginLeft: "1.3rem",
//             marginRight: "1.3rem",
//             marginTop: 0,
//           }}
//         />
//         <div className="d-flex justify-content-end  pointer " onClick={() => setMenuClose(!menuClose)}>
//           <AiOutlineMenu />
//         </div>
//         <div className="flex-column d-flex justify-content-center align-items-center ">
//           {/* {rolePermission === "ADMIN" || rolePermission === "RECRUITMENT" ? (
//           <div className="border border-primary p-2 m-5 side-bar--icon">
//             <NavLink to="/hrtools" style={{ textDecoration: "none", color: "var(--orrenge)" }}>
//               <h6 className=" fw-semibold lh-1">HR</h6> <p className="side-bar--icon---p">Dashboard</p>
//             </NavLink>
//           </div>
//         ) : (
//           <div className="border border-primary p-2 m-5 side-bar--icon">
//             <NavLink to="" style={{ textDecoration: "none", color: "var(--orrenge)" }}>
//               <h6 className="fw-semibold lh-1">EE</h6> <p className="side-bar--icon---p">Dashboard</p>
//             </NavLink>
//           </div>
//         )} */}

//           <ul className="text-start p-1  mt-3">
//             {menuItems &&
//               menuItems.map((item, index) => (
//                 <li key={index} className="text-nowrap p-1 list-unstyled side-bar--button">
//                   <NavLink
//                     to={item.path}
//                     onClick={() => handleMenuItemClick(item, index)}
//                     className={`align-items-center gap-2 py-2 my-1 ${activePath === item.path ? "activenavlink" : ""}`}
//                   >
//                     <span>{item.icon}</span>
//                     {!menuClose && <span className="ps-2">{item.title}</span>}

//                     {activePath === item.path && !menuClose && (
//                       <ul className="list-unstyled ps-3">
//                         {item.subLinks &&
//                           item.subLinks.map((subItem, i) => (
//                             <NavLink to={subItem.link} key={i}>
//                               <li
//                                 className={`sub-li ${activeSublink === subItem.link ? "activeSublink" : ""}`}
//                                 onClick={() => handleSubItemClick(subItem)}
//                               >
//                                 <div>{subItem.title}</div>
//                               </li>
//                             </NavLink>
//                           ))}
//                       </ul>
//                     )}
//                   </NavLink>
//                 </li>
//               ))}
//           </ul>
//         </div>
//       </div>
//       <div className=" d-flex justify-content-center align-items-end pt-3 ">
//         <img
//           src="/Singhtek-logo.png"
//           alt="company logo"
//           className="img-fluid"
//           style={{ maxWidth: "125px", minWidth: "75px" }}
//         />
//       </div>
//     </>
//   );
// };

// export default SidebarContent;

// import React, { useState, useEffect } from "react";
// import { NavLink, useLocation } from "react-router-dom";
// import { lendingDashboardSideMenu, dashboardSideMenu } from "../../common/json/MenuContent";
// import { AiOutlineMenu } from "react-icons/ai";
// import isResourceAvailable from "utils/permit_util";

// const SidebarContent = () => {
//   const location = useLocation();
//   const [menuItems, setMenuItems] = useState([]);
//   const [activePath, setActivePath] = useState(location.pathname);
//   const [activeSublink, setActiveSublink] = useState(null);
//   const [menuClose, setMenuClose] = useState(false);

//   const rolePermission = isResourceAvailable("role");

//   // Handle menu collapse on smaller screens
//   useEffect(() => {
//     const handleMediaChange = (e) => {
//       setMenuClose(e.matches);
//     };
//     const mediaQuery = window.matchMedia("(max-width: 990px)");
//     mediaQuery.addEventListener("change", handleMediaChange);
//     setMenuClose(mediaQuery.matches);

//     return () => {
//       mediaQuery.removeEventListener("change", handleMediaChange);
//     };
//   }, []);

//   // Filter menu items based on rolePermission
//   useEffect(() => {
//     if (rolePermission === "ADMIN" || rolePermission === "RECRUITMENT") {
//       setMenuItems(location.pathname === "/" ? lendingDashboardSideMenu : dashboardSideMenu);
//     } else {
//       const filteredLendingMenu = lendingDashboardSideMenu.filter(
//         (item) => item.id === 1 || item.id === 2 // Only HOME and Dashboard
//       );
//       const filteredDashboardMenu = dashboardSideMenu.filter(
//         (item) => item.title === "Candidate Onboarding" || item.title === "Employee Profile"
//       );
//       setMenuItems(location.pathname === "/" ? filteredLendingMenu : filteredDashboardMenu);
//     }
//   }, [location, rolePermission]);

//   // Handle item clicks
//   const handleMenuItemClick = (item) => {
//     setActivePath(item.path);
//   };

//   const handleSubItemClick = (subItem) => {
//     setActiveSublink(subItem.link);
//   };

//   return (
//     <>
//       <div className={`sideBar ${menuClose ? "sideBar-close" : ""}`} style={{ height: "750px" }}>
//         <hr style={{ color: "#827B7B", margin: "0 1.3rem" }} />
//         <div className="d-flex justify-content-end pointer pt-2" onClick={() => setMenuClose(!menuClose)}>
//           <AiOutlineMenu />
//         </div>
//         <div className="flex-column d-flex justify-content-center align-items-center">
//           <ul className="text-start p-1 mt-3">
//             {menuItems.map((item, index) => (
//               <li key={index} className="text-nowrap p-1 list-unstyled side-bar--button">
//                 <NavLink
//                   to={item.path}
//                   onClick={() => handleMenuItemClick(item)}
//                   className={`align-items-center gap-2 py-2 my-1 ${activePath === item.path ? "activenavlink" : ""}`}
//                 >
//                   <span>{item.icon}</span>
//                   {!menuClose && <span className="ps-2">{item.title}</span>}

//                   {activePath === item.path && !menuClose && item.subLinks && (
//                     <ul className="list-unstyled ps-3">
//                       {item.subLinks.map((subItem, i) => (
//                         <NavLink to={subItem.link} key={i}>
//                           <li
//                             className={`sub-li ${activeSublink === subItem.link ? "activeSublink" : ""}`}
//                             onClick={() => handleSubItemClick(subItem)}
//                           >
//                             <div>{subItem.title}</div>
//                           </li>
//                         </NavLink>
//                       ))}
//                     </ul>
//                   )}
//                 </NavLink>
//               </li>
//             ))}
//           </ul>
//         </div>
//       </div>
//       <div className="d-flex justify-content-center align-items-center" style={{ width: "100%" }}>
//         <img src="/Singhtek-logo.png" alt="company logo" className="sidebarlogo-image" />
//       </div>
//     </>
//   );
// };

// export default SidebarContent;

import React, { useState, useEffect } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { lendingDashboardSideMenu, dashboardSideMenu } from "../../common/json/MenuContent";
import { AiOutlineMenu } from "react-icons/ai";
import isResourceAvailable from "utils/permit_util";

const SidebarContent = () => {
  const location = useLocation();
  const [menuItems, setMenuItems] = useState([]);
  const [activePath, setActivePath] = useState(location.pathname);
  const [activeSublink, setActiveSublink] = useState(null);
  const [menuClose, setMenuClose] = useState(false);

  const rolePermission = isResourceAvailable("role");

  // Handle menu collapse on smaller screens
  useEffect(() => {
    const handleMediaChange = (e) => {
      setMenuClose(e.matches);
    };
    const mediaQuery = window.matchMedia("(max-width: 990px)");
    mediaQuery.addEventListener("change", handleMediaChange);
    setMenuClose(mediaQuery.matches);

    return () => {
      mediaQuery.removeEventListener("change", handleMediaChange);
    };
  }, []);

  // Filter menu items based on rolePermission
  useEffect(() => {
    const isHomePage = location.pathname === "/";
    if (rolePermission === "ADMIN" || rolePermission === "RECRUITMENT") {
      setMenuItems(isHomePage ? lendingDashboardSideMenu : dashboardSideMenu);
    } else {
      const filteredLendingMenu = lendingDashboardSideMenu.filter(
        (item) => item.id === 1 || item.id === 2 // Only HOME and Dashboard
      );
      const filteredDashboardMenu = dashboardSideMenu.filter(
        (item) => item.title === "Candidate Onboarding" || item.title === "Employee Profile"
      );
      setMenuItems(isHomePage ? filteredLendingMenu : filteredDashboardMenu);
    }
  }, [location.pathname, rolePermission]);

  // Handle item and subitem clicks
  const handleMenuItemClick = (item) => {
    setActivePath(item.path);
  };

  const handleSubItemClick = (subItem) => {
    setActiveSublink(subItem.link);
  };

  return (
    <>
      <div className={`sideBar ${menuClose ? "sideBar-close" : ""}`}>
        <div style={{ height: "750px" }}>
          <hr style={{ color: "#827B7B", margin: "0 1.3rem" }} />
          <div className="d-flex justify-content-end pointer pt-2" onClick={() => setMenuClose(!menuClose)}>
            <AiOutlineMenu />
          </div>
          <div className="flex-column d-flex justify-content-center align-items-center">
            <ul className="text-start p-1 mt-3">
              {menuItems.map((item, index) => (
                <li key={index} className="text-nowrap p-1 list-unstyled side-bar--button">
                  <NavLink
                    to={item.path}
                    onClick={() => handleMenuItemClick(item)}
                    className={`align-items-center gap-2 py-2 my-1 ${activePath === item.path ? "activenavlink" : ""}`}
                  >
                    <span>{item.icon}</span>
                    {!menuClose && <span className="ps-2">{item.title}</span>}
                    {activePath === item.path && !menuClose && item.subLinks && (
                      <ul className="list-unstyled ps-3">
                        {item.subLinks.map((subItem, i) => (
                          <NavLink to={subItem.link} key={i}>
                            <li
                              className={`sub-li ${activeSublink === subItem.link ? "activeSublink" : ""}`}
                              onClick={() => handleSubItemClick(subItem)}
                            >
                              <div>{subItem.title}</div>
                            </li>
                          </NavLink>
                        ))}
                      </ul>
                    )}
                  </NavLink>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className="d-flex justify-content-center align-items-center">
          <img
            src="/Singhtek-logo.png"
            alt="company logo"
            className="sidebarlogo-image"
            style={{
              maxWidth: "125px",
              minWidth: "75px",
              width: "100%",
              height: "auto", // Maintain aspect ratio
            }}
          />
        </div>
      </div>
    </>
  );
};

export default SidebarContent;
