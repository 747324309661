import React from "react";
import { loginPageBanner, squareDots } from "assets/images";
import { useLocation } from "react-router-dom";
import "../../assets/style/loginpage.css";
import { Login, ForgotPassword, OtpVerification, NewPassword } from "./LoginTransit";

const LoginPage = () => {
  let location = useLocation();

  let pathname = location.pathname;

  const renderPages = {
    "/": Login,
    "/login": Login,
    "/forgotpassword": ForgotPassword,
    "/otpverification": OtpVerification,
    "/newpassword": NewPassword,
  };

  const renderPage = () => {
    const Components = renderPages[pathname];
    if (!Components) return;
    return (
      <div>
        <Components />
      </div>
    );
  };

  return (
    <>
      <section className="background">
        <div className="boxshadow loginMaindiv">
          <div className="d-flex align-items-center justify-content-center gap-5">
            <div className="d-none d-md-flex">
              <div className=" d-flex flex-column justify-content-center align-items-center">
                <img src="/HRMS_LOGO.svg" alt="HRMS Logo" width="250px" />
                <img src={loginPageBanner} alt="Banner" />
              </div>
            </div>
            <div className="boxshadow mainlogininputbox">
              <div>
                <div className="squareBoxDots">
                  <img src={squareDots} alt="box" />
                </div>
                {renderPage()}
                <div className="squareBoxDotsright">
                  <img src={squareDots} alt="box" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default LoginPage;
