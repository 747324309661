import React, { useEffect, useState } from "react";
import "../../../../assets/style/candidate-preview.css";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import { AlertMessage, Button, CommonModal, CoreLoader, DownloadIcon, Dropdown, Input, Label } from "common/components";
import apiManager from "networking/baseAPIManager";
import "../../../../assets/style/Responsive.css";
import userDefaultImage from "../../../../assets/images/UserDefultImage.png";
import isResourceAvailable from "utils/permit_util";
let BASE_URL = process.env.REACT_APP_BASE_URL;

const CandidatePreview = () => {
  const [userData, setUserData] = useState({});
  let bankDetails = userData?.bankdetails || {};
  let ctcDetails = userData?.ctcDetails || {};

  let params = useParams();
  let empid = params?.empid;
  const showApprovRejectButton = isResourceAvailable("role");

  useEffect(() => {
    if (empid) {
      getAllDataCandidate();
    }
  }, [empid]);

  const getAllDataCandidate = () => {
    apiManager
      .candidateAllData(empid)
      .then((res) => {
        setUserData(res.data);
      })
      .catch((err) => {
        console.log(err, "api Error allData");
      });
  };

  const navigate = useNavigate();
  let documents = userData?.documents || [{}];
  documents = documents[0];

  let status = userData?.status;

  const [open, setOpen] = useState();
  const [formData, setFormData] = useState({});
  const [roles, setRoles] = useState([]);
  const [getDesignation, setDesignation] = useState([]);
  const [getBankName, setGetBankName] = useState([]);
  const [errors, setErrors] = useState({});
  const [selectCountry, setSelectCountry] = useState([]);
  const [selectState, setSelectState] = useState([]);
  const [selectCity, setSelectCity] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showMSG, setShowMSG] = useState(false);
  const [msgType, setmsgType] = useState("info");

  const designation = formData?.department === "" ? formData.department : formData?.department?.toLowerCase();

  useEffect(() => {
    setLoading(true);
    apiManager
      .country()
      .then((country) => {
        setLoading(false);
        setSelectCountry(country?.data);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  }, []);

  useEffect(() => {
    if (formData?.country) {
      setLoading(true);

      apiManager
        .state(formData?.country)
        .then((state) => {
          setLoading(false);
          setSelectState(state?.data);
        })
        .catch((error) => {
          setLoading(false);
          console.log(error);
        });
    }
  }, [formData?.country]);

  useEffect(() => {
    if (formData?.state) {
      setLoading(true);
      apiManager
        .city(formData?.state)
        .then((city) => {
          setLoading(false);
          setSelectCity(city?.data);
        })
        .catch((error) => {
          setLoading(false);
          console.log(error);
        });
    }
  }, [formData?.state]);

  const handleChange = (event, key, val) => {
    let value = event?.target?.value || val;
    formData[key] = value;
    errors[key] = "";
    setFormData({ ...formData });
  };

  const handleSaveData = () => {
    let allErrors = {};

    if (!formData?.email) {
      allErrors.email = "Email is required";
    } else if (!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(formData.email)) {
      allErrors.email = "Invalid email address";
    } else if (formData.email.length > 320) {
      allErrors.email = "Email must be 320 characters or less";
    } else {
      const emailDomain = formData.email.split("@")[1];

      if (!emailDomain) {
        allErrors.email = "Invalid email address"; // Invalid if there's no domain part
      } else {
        // Split the domain part by dots
        const domainParts = emailDomain.split(".");

        // Check that there are exactly two domain levels
        if (domainParts.length !== 2) {
          allErrors.email = "Invalid email domain"; // Invalid if the domain doesn't have exactly two levels
        }
      }
    }

    if (!formData?.department) {
      allErrors.department = "Department is required";
    }

    if (!formData?.designation) {
      allErrors.designation = "Designation is required";
    }

    if (!formData?.country) {
      allErrors.country = "Country is required";
    }

    if (!formData?.state) {
      allErrors.state = "State is required";
    }

    if (!formData?.city) {
      allErrors.city = "City is required";
    }

    if (!formData?.zip) {
      allErrors.zip = "Zip is required";
    } else if (!/^\d{1,6}$/.test(formData.zip)) {
      allErrors.zip = "Zip must be a valid number with up to 6 digits";
    }

    setErrors({ ...allErrors });

    if (Object.keys(allErrors).length) {
      return;
    }

    let payload = {
      empid: empid,
      company_email: formData?.email.toLowerCase(),
      department: formData?.department,
      designation: formData?.designation,
      country: formData?.country,
      state: formData?.state,
      city: formData?.city,
      zip: formData?.zip,
    };
    setLoading(true);

    apiManager
      .appEmployeeDepartment(payload)
      .then((response) => {
        setLoading(false);
        setOpen(false);
        setmsgType("success");
        setShowMSG(response?.data?.message || "details updated successfully");
        setFormData({});
        getAllDataCandidate();
      })
      .catch((err) => {
        setmsgType("error");
        setShowMSG(err?.response?.data?.error?.company_email || "failed to update");
        setOpen(false);
        setLoading(false);
      });
  };

  const handleSaveBankDetailsData = () => {
    let errors = {};

    if (formData?.accountNo !== formData?.confirmAccount) {
      errors.confirmAccount = "Account numbers do not match";
    }

    const accountNo = formData?.accountNo;

    if (!accountNo) {
      errors.accountNo = "Account No is Required";
    } else {
      if (!/^\d{10,}$/.test(accountNo)) {
        errors.accountNo = "Account number must be more than 9 digits and contain only numbers";
      }
      if (/[\s\D]|[-+*/=]/.test(accountNo)) {
        errors.accountNo =
          "Account number must not contain spaces, letters, special characters, or mathematical symbols";
      }
    }
    if (!formData?.bankName) {
      errors.bankName = "Bank Name is Required";
    }

    if (!formData?.ifsccode) {
      errors.ifsccode = "IFSC Code is Required";
    } else {
      // Alphanumeric pattern for IFSC Code validation
      const ifscCodePattern = /^[A-Z]{4}0[A-Z0-9a-z]{6}$/;
      if (!ifscCodePattern.test(formData.ifsccode)) {
        errors.ifsccode = "Invalid IFSC Code";
      }
    }

    setErrors({ ...errors });
    if (Object.keys(errors).length) {
      return;
    }

    let payload = {
      empid: empid,
      bank_name: formData.bankName,
      account_no: formData?.accountNo,
      ifsc_code: formData?.ifsccode,
    };

    setLoading(true);
    apiManager
      .appEmployeeAccountDetails(payload)
      .then((response) => {
        setLoading(false);
        setmsgType("success");
        setShowMSG(response?.data?.message || "details updated successfully");
        setOpen(false);
        setFormData({});
        getAllDataCandidate();
      })
      .catch((err) => {
        setLoading(false);
        setmsgType("error");
        setShowMSG(err?.response?.data?.message || "failed to update");
        console.log(err);
      });
  };

  const handleSaveCTCDetails = () => {
    let errors = {};
    if (!formData?.totalctc) {
      errors.totalctc = "Total CTC is required";
    }

    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      return;
    }

    let payload = {
      empid: empid,
      totalctc: formData?.totalctc,
      monthlycompensation: formData?.monthlycompensation,
      // hra: formData?.hra,
      // pf: formData?.pf,
      // insurance: formData?.insurance,
      // tax: formData?.tax,
    };

    setLoading(true);

    apiManager
      .appEmployeeCTCDetails(payload)
      .then((response) => {
        setmsgType("success");
        setShowMSG(response?.data?.message || "Details updated successfully");

        setTimeout(() => {
          navigate("/hrtools/employee-onboarding#completed");
        }, 500);

        setFormData({});
        setOpen(false);
      })
      .catch((err) => {
        setmsgType("error");
        setShowMSG(err?.response?.data?.message || "Failed to update");
        console.error(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getRolesList = () => {
    setLoading(true);

    apiManager
      .getDropdownTypes("department")
      .then((response) => {
        setLoading(false);
        setRoles(response?.data?.data);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };
  const getDesignationList = () => {
    setLoading(true);

    apiManager
      .getDesignation(designation)
      .then((response) => {
        setLoading(false);
        setDesignation(response?.data?.data);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };
  const getBankNameList = () => {
    setLoading(true);

    apiManager
      .getDropdownTypes("bankname")
      .then((response) => {
        setLoading(false);

        setGetBankName(response?.data?.data);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };
  useEffect(() => {
    if (designation) {
      getDesignationList();
    }

    getRolesList();
    getBankNameList();
  }, [designation]);

  const renderAddressForm = () => {
    return (
      <div className="my-4 me-5 pe-4  normalBold">
        <div className="mb-3">work Location</div>
        <div className="row row-cols-2 ms-5 ">
          <div className="col">
            <Label label="Country" required />
            <Dropdown
              valueKey="id"
              type="text"
              name={"country"}
              value={formData["country"]}
              options={selectCountry}
              onChange={(e) => {
                handleChange(e, "country");
              }}
              touched={true}
              error={errors["country"]}
            />
          </div>
          <div>
            <Label label="State" required />
            <Dropdown
              valueKey="id"
              type="text"
              name={"state"}
              value={formData["state"]}
              options={selectState}
              onChange={(e) => {
                handleChange(e, "state");
              }}
              touched={true}
              error={errors["state"]}
            />
          </div>

          <div>
            <Label label="City" required />
            <Dropdown
              valueKey="id"
              type="text"
              name={"city"}
              value={formData["city"]}
              options={selectCity}
              onChange={(e) => {
                handleChange(e, "city");
              }}
              touched={true}
              error={errors["city"]}
            />
          </div>
          <div>
            <Label label="Zip Code" required />
            <Input
              type="text"
              name="zip"
              value={formData["zip"]}
              onChange={(e) => {
                handleChange(e, "zip");
              }}
              touched={true}
              error={errors["zip"]}
              maxlength={6}
            />
          </div>
        </div>
      </div>
    );
  };

  const renderApproveModal = () => {
    return (
      <CommonModal title="Extra Detalis" size="md" open={open} onClose={() => setOpen(false)}>
        <div className="col-lg-12 py-2  px-5 ">
          <Label label="Company Email" required labelclasses="pb-1" />
          <Input
            type="Email"
            value={formData?.email?.toLowerCase() || ""}
            parentclasses="pb-2"
            onChange={(e) => handleChange(e, "email")}
            placeholder="Company Email"
            touched={true}
            error={errors?.email}
            inputClass="w-50"
            maxLength={45}
          />
          <Label label="Department" required labelclasses="pb-1" />
          <Dropdown
            valueKey="id"
            type="text"
            name={"department"}
            value={formData["department"]}
            options={roles}
            onChange={(e) => {
              handleChange(e, "department");
            }}
            touched={true}
            error={errors["department"]}
          />
          <Label label="Designation" required labelclasses="pb-1" />
          <Dropdown
            valueKey="id"
            type="text"
            name={"designation"}
            value={formData["designation"]}
            options={getDesignation}
            onChange={(e) => {
              handleChange(e, "designation");
            }}
            touched={true}
            error={errors["designation"]}
          />
          {renderAddressForm()}
          <div className="d-flex justify-content-end pe-3">
            <Button onClick={handleSaveData} label="Submit" />
          </div>
        </div>
      </CommonModal>
    );
  };

  const bankDetailsModal = () => {
    return (
      <CommonModal title="Bank Account Detail" size="sm" open={open} onClose={() => setOpen(false)}>
        <div className="row col-lg-12 py-2 px-5 ">
          <Label label="Bank Name" parentclasses="col-xl-4 col-lg-5 col-md-6" required />
          <Dropdown
            value={formData?.bankName || ""}
            parentclasses="col-xl-12"
            options={getBankName}
            onChange={(e) => handleChange(e, "bankName")}
            placeholder="Bank Name"
            touched={true}
            error={errors?.bankName}
            inputClass="w-50"
          />
          <Label label="Bank Account No" parentclasses="col-xl-4 col-lg-5 col-md-6 " required />
          <Input
            value={formData?.accountNo || ""}
            parentclasses="col-xl-12"
            onChange={(e) => handleChange(e, "accountNo")}
            label="Bank Account No"
            placeholder="******************"
            maxLength={17}
            minLength={10}
            touched={true}
            error={errors?.accountNo}
            inputClass="w-50"
            sidelabel
          />
          <Label label="Confirm Account No" parentclasses="col-xl-4 col-lg-5 col-md-6  " required />
          <Input
            value={formData?.confirmAccount || ""}
            parentclasses="col-xl-12 "
            onChange={(e) => handleChange(e, "confirmAccount")}
            placeholder="******************"
            maxLength={17}
            minLength={11}
            touched={true}
            error={errors?.confirmAccount}
            inputClass="w-50"
            onPaste={(e) => {
              e.preventDefault();
              return false;
            }}
            onCopy={(e) => {
              e.preventDefault();
              return false;
            }}
            sidelabel
          />
          <Label label="IFSC Code" parentclasses="col-xl-4 col-lg-5 col-md-6" required />
          <Input
            value={formData?.ifsccode?.toUpperCase() || ""}
            parentclasses="col-xl-12 pb-4"
            onChange={(e) => handleChange(e, "ifsccode")}
            placeholder="IFSC Code"
            touched={true}
            error={errors?.ifsccode}
            inputClass="w-50"
            maxLength={11}
            sidelabel
          />
        </div>
        <div className="d-flex justify-content-end pe-5 me-3">
          <Button onClick={handleSaveBankDetailsData} label="Submit" />
        </div>
      </CommonModal>
    );
  };
  const formatNumber = (num) => {
    const str = num.toString().split(".");
    const intPart = str[0];
    const decPart = str.length > 1 ? "." + str[1] : "";
    const lastThree = intPart.substring(intPart.length - 3);
    const otherNumbers = intPart.substring(0, intPart.length - 3);
    const formattedNumber = otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + "," + lastThree;
    return formattedNumber + decPart;
  };
  const ctcDetailsModal = () => {
    return (
      <CommonModal title="Add Salary Detail" size="md" open={open} onClose={() => setOpen(false)}>
        <div className=" container row row-cols-2 px-5 gap-3 ">
          <Label label="Total CTC" parentclasses="col-xl-4 col-lg-5 col-md-6 d-flex align-items-center" required />
          <div className="col-xl-7 col-lg-6 position-relative">
            <span className="currency-symbol">₹</span>
            <Input
              value={formData?.totalctc || ""}
              className="form-control ps-4"
              onChange={(e) => {
                let val = e.target.value.replace(/,/g, ""); // Remove existing commas

                if (val === "") {
                  handleChange({ target: { name: "totalctc", value: "" } }, "totalctc");
                  formData.monthlycompensation = "";
                  setFormData({ ...formData });
                } else if (!isNaN(Number(val))) {
                  if (Number(val) >= 1000) {
                    handleChange({ target: { name: "totalctc", value: formatNumber(val) } }, "totalctc");
                  } else {
                    handleChange({ target: { name: "totalctc", value: val } }, "totalctc");
                  }

                  let monthly = (Number(val) / 12).toFixed(1);
                  formData.monthlycompensation = Number(val) >= 1000 ? formatNumber(monthly) : monthly;
                  setFormData({ ...formData });
                }
              }}
              placeholder="Total CTC"
              maxLength={15}
              touched={true}
              error={errors.totalctc}
              inputClass="w-50"
            />
          </div>

          <Label label="Monthly Compensation" parentclasses="col-xl-4 col-lg-5 col-md-6 d-flex align-items-center" />
          <div className="col-xl-7 col-lg-6 position-relative">
            <span className="currency-symbol">₹</span>
            <Input
              className="form-control ps-4"
              value={formData.monthlycompensation || ""}
              onChange={(e) => {}}
              placeholder="Monthly Compensation"
              touched={true}
              error={errors?.monthlycompensation}
              inputClass="w-50"
              disabled
            />
          </div>

          <Label label="HRA" parentclasses="col-xl-4 col-lg-5 col-md-6 d-flex align-items-center" />
          <Input
            parentclasses="col-xl-7 col-lg-6 "
            disabled
            value={formData?.hra || ""}
            onChange={(e) => handleChange(e, "hra")}
            placeholder="HRA"
            touched={true}
            error={errors?.hra}
            inputClass="w-50"
          />
          <Label label="PF" parentclasses="col-xl-4 col-lg-5 col-md-6 d-flex align-items-center" />
          <Input
            disabled
            parentclasses="col-xl-7 col-lg-6 "
            value={formData?.pf || ""}
            onChange={(e) => handleChange(e, "pf")}
            placeholder="PF"
            touched={true}
            error={errors?.pf}
            inputClass="w-50"
          />
          <Label label="Insurance" parentclasses="col-xl-4 col-lg-5 col-md-6 d-flex align-items-center" />
          <Input
            parentclasses="col-xl-7 col-lg-6  "
            disabled
            value={formData?.insurance || ""}
            onChange={(e) => handleChange(e, "insurance")}
            placeholder="Insurance"
            touched={true}
            error={errors?.insurance}
            inputClass="w-50"
          />
          <Label label="Tax" parentclasses="col-xl-4 col-lg-5 col-md-6 d-flex align-items-center" />
          <Input
            disabled
            parentclasses="col-xl-7 col-lg-6  pb-3"
            value={formData?.tax || ""}
            onChange={(e) => handleChange(e, "tax")}
            placeholder="Tax"
            touched={true}
            error={errors?.tax}
            inputClass="w-50"
          />
        </div>
        <div className="col-md-11 d-flex justify-content-end  pe-5  ">
          <Button onClick={handleSaveCTCDetails} label="Submit" />
        </div>
      </CommonModal>
    );
  };

  const handleApprove = () => {
    if (status === "pending") {
      setOpen("department");
    } else if (!userData?.bankdetails?.bank_name) {
      setOpen("bankdetails");
    } else {
      setOpen("ctcModal");
    }
  };

  const renderModals = () => {
    if (!open) {
      return <></>;
    }
    let modals = {
      department: renderApproveModal,
      bankdetails: bankDetailsModal,
      ctcModal: ctcDetailsModal,
    };

    if (modals[open]) {
      return modals[open]();
    }
    return renderApproveModal();
  };

  let btnTitle = () => {
    if (status === "pending") {
      return "Approve";
    } else if (!userData?.bankdetails) {
      return "Update Bank Details";
    } else {
      return "Add Salary Details";
    }
  };

  let imageToShow = userData?.image ? BASE_URL + "/" + userData?.image : userDefaultImage;

  const downloadAttachment = async (data) => {
    let BASE_URL = process.env.REACT_APP_BASE_URL;
    const attachmentData = `${BASE_URL}/${data}`;

    if (!attachmentData) {
      console.error("No attachment data found.");
      return;
    }

    try {
      const response = await fetch(attachmentData);
      const blob = await response.blob();
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", data);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    } catch (error) {
      console.error("Error fetching or triggering download:", error);
    }
  };

  return (
    <>
      <AlertMessage show={showMSG} setShow={setShowMSG} type={msgType} />

      <div className="gap-3 text-center subheaderbackgroundoffWhite p-2 d-flex align-items-center justify-content-center">
        <h3>Candidate Preview </h3>
      </div>
      <div className="card_modify ">
        <div className="p-4">
          <div className="div2Sections">
            <div className="userImageBlock">
              <img className="userImage " src={imageToShow} alt="userImage" />
            </div>

            <div className="row mb-2 ">
              <table className="candidatepreviewform">
                <tr>
                  <th>Name</th>
                  <td className="text-secondary">
                    {userData?.firstname} {userData?.middlename} {userData?.lastname}
                  </td>
                </tr>
                {userData?.email ? (
                  <tr>
                    <th>Email Id</th>
                    <td className="text-secondary">{userData?.email}</td>
                  </tr>
                ) : (
                  ""
                )}
                <tr>
                  <th>Mobile No</th>
                  <td className="text-secondary">{userData?.mobile_number}</td>
                </tr>
                <tr>
                  <th>Documental DOB</th>
                  <td className="text-secondary">{userData?.documentDob}</td>
                </tr>
                <tr>
                  <th>Original DOB</th>
                  <td className="text-secondary">{userData?.originalDob}</td>
                </tr>
                <tr>
                  <th>Gender</th>
                  <td className="text-secondary">{userData?.gender}</td>
                </tr>
              </table>
            </div>
          </div>

          {userData?.mobile_number || userData?.department ? (
            <div className="current-Box py-2 pt-3 ps-3 mb-3">
              <div className="d-flex justify-content-between">
                <h6>Department</h6>
                <span>{userData?.department}</span>
                <div className="d-flex justify-content-around w-50 ">
                  <h6>Designation</h6>
                  <span>{userData?.designation}</span>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
          <div className="current-Box py-2 pt-3 ps-3 mb-3">
            <div className="d-flex justify-content-between">
              <h6>Aadhaar Card Number</h6>
              <span>{userData?.aadharcard_no}</span>
              <div className="d-flex justify-content-around w-50">
                <h6>PAN Card Number</h6>
                <span>{userData?.pancard_no}</span>
              </div>
            </div>
          </div>
          <div className="container-fluid current-Box mb-3">
            <div className="row mt-3 pt-2">
              <div className="col-md-3">
                <div className="ps-3">
                  <h6>Current Address</h6>
                </div>
              </div>
              <div className="col-md-9 text-secondary">
                <p style={{ wordBreak: "break-word" }}>
                  {userData?.currentAddress
                    ? [
                        userData?.currentAddress?.line1,
                        userData?.currentAddress?.line2,
                        userData?.currentAddress?.line3,
                      ]
                        .filter(Boolean)
                        .join(", ")
                    : "NA"}
                </p>
              </div>
            </div>
            <hr />
            <div className="twoboxeswithLine pt-0">
              <div className="row mb-2 ps-3">
                <div className="col-md-6 pt-3">
                  <h6>State</h6>
                </div>
                <div className="col-md-6 pt-3 text-secondary">{userData?.currentAddress?.statename || "NA"}</div>
                <div className="col-md-6 pt-3">
                  <h6>Zip</h6>
                </div>
                <div className="col-md-6 pt-3 text-secondary">{userData?.currentAddress?.zip || "NA"}</div>
              </div>
              <div className="vr"></div>
              <div className="row mb-2 p-0 ps-3">
                <div className="col-md-6 pt-3">
                  <h6>City</h6>
                </div>
                <div className="col-md-6 pt-3 text-secondary">{userData?.currentAddress?.cityname || "NA"}</div>
                <div className="col-md-6 pt-3">
                  <h6>Country</h6>
                </div>
                <div className="col-md-6 pt-3 text-secondary">{userData?.currentAddress?.countryname || "NA"}</div>
              </div>
            </div>
          </div>

          {/* <div className="current-Box mb-3">
            <div className="row mt-3 pt-2">
              <div className="col-md-3">
                <div className="ps-3">
                  <h6>Permanent Address</h6>
                </div>
              </div>
              <div className="col-md-9 text-secondary">
                <div>
                  {userData?.ParmanentAddress || !userData?.ParmanentAddress == "undefined"
                    ? `${userData?.ParmanentAddress?.line1}, 
                ${userData?.ParmanentAddress?.line2},
                ${userData?.ParmanentAddress?.line3}`
                    : "NA"}
                </div>
              </div>
            </div>

            <hr />
            <div className="twoboxeswithLine pt-0">
              <div className="row ps-3">
                <div className="col-md-6 pt-3">
                  <div>
                    <h6>State</h6>
                  </div>
                </div>
                <div className="col-md-6 pt-3 text-secondary">
                  <div>{userData?.ParmanentAddress?.statename ? userData?.ParmanentAddress.statename : "NA"}</div>
                </div>
                <div className="col-md-6 pt-3">
                  <div>
                    <h6>Zip</h6>
                  </div>
                </div>
                <div className="col-md-6 pt-3 text-secondary">
                  <div>{userData?.ParmanentAddress?.zip ? userData?.ParmanentAddress?.zip : "NA"}</div>
                </div>
              </div>
              <div className="vr"></div>
              <div className="row mb-2 ps-3">
                <div className="col-md-6 pt-3">
                  <div>
                    <h6>City</h6>
                  </div>
                </div>
                <div className="col-md-6 pt-3 text-secondary">
                  <div>{userData?.ParmanentAddress?.cityname ? userData?.ParmanentAddress?.cityname : "NA"}</div>
                </div>
                <div className="col-md-6 pt-3">
                  <div>
                    <h6>Country</h6>
                  </div>
                </div>
                <div className="col-md-6 pt-3 text-secondary">
                  <div>{userData?.ParmanentAddress?.countryname ? userData?.ParmanentAddress?.countryname : "NA"}</div>
                </div>
              </div>
            </div>
          </div> */}
          <div className="container-fluid current-Box mb-3">
            <div className="row mt-3 pt-2">
              <div className="col-md-3">
                <div className="ps-3">
                  <h6>Permanent Address</h6>
                </div>
              </div>
              <div className="col-md-9 text-secondary">
                <p style={{ wordBreak: "break-word" }}>
                  {userData?.ParmanentAddress
                    ? [
                        userData?.ParmanentAddress?.line1,
                        userData?.ParmanentAddress?.line2,
                        userData?.ParmanentAddress?.line3,
                      ]
                        .filter(Boolean) // Filters out undefined or empty lines
                        .join(", ")
                    : "NA"}
                </p>
              </div>
            </div>

            <hr />

            <div className="twoboxeswithLine pt-0">
              <div className="row ps-3">
                <div className="col-md-6 pt-3">
                  <h6>State</h6>
                </div>
                <div className="col-md-6 pt-3 text-secondary">{userData?.ParmanentAddress?.statename || "NA"}</div>

                <div className="col-md-6 pt-3">
                  <h6>Zip</h6>
                </div>
                <div className="col-md-6 pt-3 text-secondary">{userData?.ParmanentAddress?.zip || "NA"}</div>
              </div>

              <div className="vr"></div>

              <div className="row mb-2 ps-3">
                <div className="col-md-6 pt-3">
                  <h6>City</h6>
                </div>
                <div className="col-md-6 pt-3 text-secondary">{userData?.ParmanentAddress?.cityname || "NA"}</div>

                <div className="col-md-6 pt-3">
                  <h6>Country</h6>
                </div>
                <div className="col-md-6 pt-3 text-secondary">{userData?.ParmanentAddress?.countryname || "NA"}</div>
              </div>
            </div>
          </div>

          <div className="current-Box  mb-3">
            <div className="row mt-3  px-3">
              <h6 className="w-40 fs-5">Education Detail</h6>
            </div>
            <hr />
            <div className="twoboxeswithLine">
              <div className="row my-1 align-items-center gap-3 px-3 ">
                <div className="d-flex justify-content-between align-items-center gap-3">
                  <h6 className="w-50">Secondary Passing</h6>
                  <h6>{documents?.secondary_passing ? `${documents?.secondary_passing} %` : "NA"}</h6>
                  {documents?.secondary_doc && (
                    <Button
                      onClick={() => downloadAttachment(documents?.secondary_doc)}
                      label="Download"
                      type="button"
                    />
                  )}
                </div>
                <div className="d-flex justify-content-between align-items-center gap-3">
                  <h6 className="w-50">Senior Secondary Passing</h6>
                  <h6>{documents?.senior_passing ? `${documents?.senior_passing} % ` : "NA"}</h6>
                  {documents?.senior_doc && (
                    <Button onClick={() => downloadAttachment(documents?.senior_doc)} label="Download" type="button" />
                  )}
                </div>
                <div className="d-flex justify-content-between align-items-center gap-3">
                  <h6 className="w-50">Bachelor's Passing</h6>
                  <h6>{documents?.bachelor_passing ? `${documents?.bachelor_passing} %` : "NA"}</h6>
                  {documents?.bachelor_doc && (
                    <Button
                      onClick={() => downloadAttachment(documents?.bachelor_doc)}
                      label="Download"
                      type="button"
                    />
                  )}
                </div>
              </div>

              <div className="vr" />

              <div className="row my-1 align-items-center gap-3 px-3">
                {documents?.extra &&
                  documents?.extra.map((extraDetail, index) => {
                    return (
                      <>
                        <div className="d-flex justify-content-between align-items-center gap-3">
                          <h6 className="w-50">{extraDetail?.degreeField}</h6>
                          <h6>{extraDetail?.degreepercent ? `${extraDetail?.degreepercent} %` : "NA"}</h6>
                          <Button
                            onClick={() => downloadAttachment(extraDetail?.degreefile)}
                            label="Download"
                            type="button"
                          />
                        </div>
                      </>
                    );
                  })}
              </div>
            </div>
          </div>
          {documents?.experienceData || documents?.companyname ? (
            <div className="current-Box  mb-3">
              <div className="row mt-3 pt-2 px-3">
                <div className="col-md-8 ">
                  <div className="pb-2">
                    <h6 className="w-50 fs-5">Experience Detail</h6>
                  </div>
                  <div className="d-flex justify-content-between align-items-center gap-3">
                    <h6 className="w-50">Company Name</h6>
                    <h6 className="text-secondary w-50">{documents?.experienceData?.companyname}</h6>
                  </div>
                </div>
              </div>
              <hr />
              <div className="twoboxeswithLine">
                <div className="row my-1 align-items-center gap-3 px-3 ">
                  {documents?.experienceData?.start_date && (
                    <div className="d-flex justify-content-between align-items-center gap-4">
                      <h6>Start Date</h6>
                      <h6 className=" text-secondary">
                        {moment(documents?.experienceData?.start_date || documents?.start_date).format("LL")}
                      </h6>
                    </div>
                  )}

                  {documents?.experienceData?.compensation && (
                    <div className="d-flex justify-content-between align-items-center gap-3">
                      <h6 className="w-50">Compensation</h6>
                      <Button
                        onClick={() => downloadAttachment(documents?.experienceData?.compensation)}
                        label="Download"
                        type="button"
                      />
                    </div>
                  )}

                  {documents?.experienceData?.experienceletter && (
                    <div className="d-flex justify-content-between align-items-center gap-3">
                      <h6 className="w-50">Experience Letter</h6>
                      <Button
                        onClick={() => downloadAttachment(documents?.experienceData?.experienceletter)}
                        label="Download"
                        type="button"
                      />
                    </div>
                  )}

                  {documents?.experienceData?.offerletter && (
                    <div className="d-flex justify-content-between align-items-center gap-3">
                      <h6 className="w-50">Offer Letter</h6>
                      <Button
                        onClick={() => downloadAttachment(documents?.experienceData?.offerletter)}
                        label="Download"
                        type="button"
                      />
                    </div>
                  )}
                </div>
                <div className="vr" />
                <div className="row my-1 align-items-center gap-3 px-3">
                  {documents?.experienceData?.end_date && (
                    <div className="d-flex justify-content-between align-items-center gap-4">
                      <h6>End Date</h6>
                      <h6 className="text-secondary">
                        {moment(documents?.experienceData?.end_date || documents?.end_date).format("LL")}
                      </h6>
                    </div>
                  )}
                  {documents?.experienceData?.payslip && (
                    <div className="d-flex justify-content-between align-items-center gap-3">
                      <h6 className="w-50">Pay slip</h6>
                      <Button
                        onClick={() => downloadAttachment(documents?.experienceData?.payslip)}
                        label="Download"
                        type="button"
                      />
                    </div>
                  )}

                  {documents?.experienceData?.relievingletter && (
                    <div className="d-flex justify-content-between align-items-center gap-3">
                      <h6 className="w-50">Relieving Letter</h6>

                      <Button
                        onClick={() => downloadAttachment(documents?.experienceData?.relievingletter)}
                        label="Download"
                        type="button"
                      />
                    </div>
                  )}

                  {documents?.experienceData?.resignationletter && (
                    <div className="d-flex justify-content-between align-items-center gap-3">
                      <h6 className="w-50">Resignation Letter</h6>
                      <Button
                        onClick={() => downloadAttachment(documents?.experienceData?.resignationletter)}
                        label="Download"
                        type="button"
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
          {documents?.extraExperienceData &&
            documents?.extraExperienceData.map((extraExperienceData, index) => {
              return (
                <>
                  <div className="current-Box  mb-3">
                    <div className="row mt-3 pt-2 px-3">
                      <div className="col-md-8 ">
                        <div className="pb-2">
                          <h6 className="w-50">Extra Experience Detail</h6>
                        </div>
                        <div className="d-flex justify-content-between align-items-center gap-3">
                          <h6 className="w-50">Company Name</h6>
                          <h6 className="text-secondary w-50">{extraExperienceData.companyname}</h6>
                        </div>
                      </div>
                    </div>
                    <hr />
                    <div className="twoboxeswithLine">
                      <div className="row my-1 align-items-center gap-3 px-3 ">
                        <div className="d-flex justify-content-between align-items-center gap-4">
                          <h6>Start Date</h6>
                          <h6 className=" text-secondary">{moment(extraExperienceData?.start_date).format("LL")}</h6>
                        </div>

                        <div className="d-flex justify-content-between align-items-center gap-3">
                          <h6 className="w-50">Compensation</h6>
                          <Button
                            onClick={() => downloadAttachment(extraExperienceData?.compensation)}
                            label="Download"
                            type="button"
                          />
                        </div>
                        <div className="d-flex justify-content-between align-items-center gap-3">
                          <h6 className="w-50">Experience Letter</h6>
                          <Button
                            onClick={() => downloadAttachment(extraExperienceData?.experienceletter)}
                            label="Download"
                            type="button"
                          />
                        </div>
                        <div className="d-flex justify-content-between align-items-center gap-3">
                          <h6 className="w-50">Offer Letter</h6>

                          <Button
                            onClick={() => downloadAttachment(extraExperienceData?.offerletter)}
                            label="Download"
                            type="button"
                          />
                        </div>
                      </div>
                      <div className="vr" />
                      <div className="row my-1 align-items-center gap-3 px-3">
                        <div className="d-flex justify-content-between align-items-center gap-4">
                          <h6>End Date</h6>
                          <h6 className="text-secondary">{moment(extraExperienceData?.end_date).format("LL")}</h6>
                        </div>
                        <div className="d-flex justify-content-between align-items-center gap-3">
                          <h6 className="w-50">Payslip</h6>
                          <Button
                            onClick={() => downloadAttachment(extraExperienceData?.payslip)}
                            label="Download"
                            type="button"
                          />
                        </div>
                        <div className="d-flex justify-content-between align-items-center gap-3">
                          <h6 className="w-50">Relieving Letter</h6>
                          <Button
                            onClick={() => downloadAttachment(extraExperienceData?.relievingletter)}
                            label="Download"
                            type="button"
                          />
                        </div>
                        <div className="d-flex justify-content-between align-items-center gap-3">
                          <h6 className="w-50">Resignation Letter</h6>
                          <Button
                            onClick={() => downloadAttachment(extraExperienceData?.resignationletter)}
                            label="Download"
                            type="button"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              );
            })}

          {bankDetails.bank_name ? (
            <div className="current-Box  mb-3">
              <div className="twoboxeswithLine">
                <div className="row ps-3">
                  <div className="col-md-6 pt-3">
                    <div>
                      <h6>Bank Name </h6>
                    </div>
                  </div>
                  <div className="col-md-6 pt-3 text-secondary">
                    <div>{bankDetails.bank_name}</div>
                  </div>
                  <div className="col-md-6 pt-3">
                    <div>
                      <h6>IFSC Code</h6>
                    </div>
                  </div>
                  <div className="col-md-6 pt-3 text-secondary">
                    <div>{bankDetails.ifsc_code}</div>
                  </div>
                </div>

                <div className="vr" />
                <div className="row mb-2 ps-3">
                  <div className="col-md-6 pt-3">
                    <div>
                      <h6>Bank Account No.</h6>
                    </div>
                  </div>
                  <div className="col-md-6 pt-3 text-secondary">
                    <div>{bankDetails.account_no}</div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
          {ctcDetails.totalctc ? (
            <div className="current-Box  mb-3">
              <div className="twoboxeswithLine">
                <div className="row ps-3">
                  <div className="col-md-6 pt-3">
                    <div>
                      <h6>CTC </h6>
                    </div>
                  </div>
                  <div className="col-md-6 pt-3 text-secondary">
                    <div>&#8377; {ctcDetails.totalctc}/-</div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
          {status !== "completed" ? (
            <div className="d-flex gap-3 justify-content-end subheaderbackground p-2 px-4">
              {/* <Button
              className="btn border border-dark text-primary bg-light sub-btn"
              variant="primary"
              type="button"
              label="Edit"
            /> */}
              {status === "pending" ? (
                <Button
                  classes="btn-primary btn-outline-light "
                  onClick={() => {
                    navigate(`/hrtools/employee-onboarding/experiencedetails/${empid}`);
                  }}
                  type="button"
                  label="Back"
                />
              ) : (
                ""
              )}
              {(showApprovRejectButton === "ADMIN" || showApprovRejectButton === "RECRUITMENT") &&
              status !== "completed" ? (
                <Button
                  classes="btn-outline-light"
                  onClick={handleApprove}
                  variant="primary"
                  type="button"
                  label={btnTitle()}
                />
              ) : (
                ""
              )}

              {showApprovRejectButton === "ADMIN" || showApprovRejectButton === "RECRUITMENT" ? (
                <Button classes=" btn-outline-dark  text-primary  bg-light " type="button" label="Reject" />
              ) : (
                ""
              )}
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
      {renderModals()}
      <CoreLoader show={loading} disableLines />
    </>
  );
};

export default CandidatePreview;
