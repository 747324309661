import { Button } from "common/components";
import React from "react";
import { Message } from "rsuite";

function ConfirmationModal({ setOpen, handleLogout, setConfirm, Message }) {
  const handleCancel = () => {
    setOpen(false);
  };

  const handleConfirm = () => {
    setOpen(false);
    setConfirm(true);
  };

  return (
    <div>
      <div className="container pt-3 ">
        <div className="row text-center  px-5">
          <div className="col-md-12 ">
            <h4>{`${Message || "Are You Sure ? "}`} </h4>
          </div>
          <div className="delete-yes--btn d-flex gap-5 w-100 justify-content-center">
            <div className=" pt-3">
              <Button
                className=" yes-btn px-4"
                label="Yes"
                onClick={() => {
                  handleConfirm();
                }}
                size="xs"
              />
            </div>
            <div className=" pt-3">
              <Button className=" delete-submit-btn " onClick={handleCancel} label="Cancel" />
            </div>
          </div>
          <div className="col-md-12  pt-4"></div>
        </div>
      </div>
    </div>
  );
}

export default ConfirmationModal;
