import React, { useEffect, useState } from "react";
import { IoMdNotificationsOutline } from "react-icons/io";
import { FiUser } from "react-icons/fi";
import { MdOutlineLogout } from "react-icons/md";
import { clearLocalData } from "utils/localStorageHelper";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setAuthToken } from "redux/slice";
import "../assets/style/Responsive.css";
import { CommonModal } from "common/components";
import ConfirmationModal from "common/components/ConfirmationModal";

const Header = () => {
  const [open, setOpen] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isLoggedIn = useSelector((state) => state?.data?.token);

  const handleLogout = () => {
    console.log("Done");
    navigate("/login");
    clearLocalData();
    dispatch(setAuthToken(""));
  };

  useEffect(() => {
    confirm && handleLogout();
  }, [confirm]);

  return (
    <div>
      <div className="container-fluid">
        <div className="row">
          <nav className="navbar navbar-expand-lg navbar-light header ">
            <div className="container-fluid">
              <div className="">
                <a className="navbar-brand px-4 pointer" onClick={() => navigate("/")}>
                  <img src="/HRMS_LOGO.svg" alt="logo" width="125px" />
                </a>
              </div>

              <button
                className="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon"></span>
              </button>
              <div className="collapse navbar-collapse " id="navbarSupportedContent">
                <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                  <li className="nav-item">
                    <a className="nav-link" aria-current="page" href="#">
                      <IoMdNotificationsOutline className="icons" />
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#">
                      <FiUser className="icons" />
                    </a>
                  </li>
                  {isLoggedIn && (
                    <li className="nav-item">
                      <a className="nav-link" href="#" onClick={() => setOpen(true)}>
                        <MdOutlineLogout className="icons" />
                      </a>
                    </li>
                  )}
                </ul>
              </div>
            </div>
          </nav>
        </div>
      </div>
      <CommonModal open={open} onClose={() => setOpen(false)} size="sm" title={"Log Out"}>
        <ConfirmationModal setConfirm={setConfirm} setOpen={setOpen} Message="Are you sure you want to log out ?" />
      </CommonModal>
    </div>
  );
};

export default Header;
